<template>
  <div>
    <BlockHero/>
    <BlockPlay/>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                Навіны
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <a href="https://kinakong.by/cinderella-man/">
                    «На імя Папялушка»: новы фільм ужо на VokaTV
                  </a>
                </li>
                <li class="list-group-item">
                  <a href="https://kinakong.by/suberbook-belarusian/">
                    Мультфільм «Суперкніга» цяпер на беларускай!
                  </a>
                </li>
                <li class="list-group-item">
                  <a href="https://kinakong.by/category/news/">
                    Усе навіны
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                На VokaTV
              </div>

              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <a class="link-secondary" href="https://voka.tv/ru-RU/movies/e351bf87-28f0-48ad-a670-d298068bafff">
                    Грошы на дваіх
                  </a>
                </li>
                <li class="list-group-item">
                  <a class="link-secondary" href="https://voka.tv/ru-RU/movies/chalavek-yak--plakau">
                    Чалавек, які плакаў
                  </a>
                </li>
                <li class="list-group-item">
                  <a class="link-secondary" href="https://voka.tv/ru-RU/movies/skot-p-l-grym-suprats-us-h?autoplay=true">
                    Скот Пілігрым супраць усіх
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                На VokaTV
              </div>

              <ul class="list-group list-group-flush">
                <li class="list-group-item"><a class="link-secondary" href="https://voka.tv/ru-RU/movies/zhandar-z-san-trape">Жандар з Сан-Трапэ</a></li>
                <li class="list-group-item"><a class="link-secondary" href="https://voka.tv/ru-RU/movies/za-zhmenu-dalyarau">За жменю даляраў</a></li>
                <li class="list-group-item"><a class="link-secondary" href="https://voka.tv/ru-RU/movies/hob-t-nechakanaya-vandrouka">Хобіт</a></li>
<!--                <li class="list-group-item"><a class="link-secondary" href="https://voka.tv/ru-RU/collections/cinevoka-kino-pa-belarusku">Усё Cinevoka</a></li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import BlockPlay from "@/components/Parts/BlockPlay.vue";
import BlockHero from "@/components/Parts/BlockHero.vue";

document.title = "Плэер | KinaKong";
</script>

<style scoped>
  .card {
    margin: 2rem 0;
    width: 100%;
  }

  .card-header {
    background: #212529;
    color: white;
    font-weight: bold;
  }
</style>